import * as React from "react";
import { Typography } from "@papercup-ai/crane";

export const Home: React.FunctionComponent = () => {
  return (
    <section className="cf ph3 ph5-ns pb5 bg-yellow black-70" id="features">
      <div className="mw9 center">
        <Typography.H1 className="fl w-100 mt5 f5 ttu tracked fw6 b">
          Origami.
        </Typography.H1>
        <article className="pv2 fl w-100 ">
          <Typography.H2 className="f4 f1-ns fw6 mb2">
            Papercup&apos;s <span className="i">next</span> web application.
          </Typography.H2>
          <Typography.H1>
            It is change, continuing change, inevitable change, that is the
            dominant factor in society today. No sensible decision can be made
            any longer without taking into account not only the world as it is,
            but the world as it will be.
          </Typography.H1>
        </article>
      </div>
    </section>
  );
};
